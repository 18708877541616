<template>
  <div class="flex a-cascader">
    <select @change="pChange" v-model="ps"><option v-for="p in data" :key="p.postCode" :value="p.postCode">{{p.name}}</option></select>
    <select @change="cChange" v-model="cs"><option v-for="c in cArr" :key="c.postCode" :value="c.postCode">{{c.name}}</option></select>
    <select @change="sChange" v-model="ss"><option v-for="s in sArr" :key="s.postCode" :value="s.postCode">{{s.name}}</option></select>
  </div>
</template>
<script>
export default {
  props: {
    data: Array
  },
  data() {
    return {
      ps: '',
      cs: '',
      ss: '',
      p: '',
      c: '',
      cArr: [],
      s: '',
      sArr: []
    }
  },
  methods: {
    pChange(e) {
      let selectp = this.data.filter(p => p.postCode==e.target.value)[0]
      this.cArr = selectp.list
      this.sArr = []
      this.ss = ''
      this.p = {
        label: selectp.name
      }
    },
    cChange(e) {
      let selectc = this.cArr.filter(c => c.postCode==e.target.value)[0]
      this.sArr = selectc.list
      this.c = {
        label: selectc.name
      }
    },
    sChange(e) {
      this.s = {
        label: this.sArr.filter(s => s.postCode==e.target.value)[0].name,
        value: e.target.value
      }
      this.$emit('on-change',false,[this.p,this.c,this.s])
    }
  }
}
</script>
<style lang="less" scoped>
.a-cascader{
  justify-content: space-between;
  select {
    width: 120px;
    height: 55px;
    font-size: 16px;
    background: none;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    appearance: none;
  }
}
</style>